export default {
  chart: {
    type: "bar",
    stacked: false,
    width: "100%",
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: `<i aria-hidden="true" class="v-icon notranslate mdi mdi-download theme--light"></i>`,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          height: 500,
          columnDelimiter: ",",
          headerCategory: "category",
          headerValue: "value",
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString();
          }
        },
        svg: {
          filename: undefined
        },
        png: {
          filename: undefined
        }
      },
      autoSelected: "zoom",
      fontFamily: "inherit"
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
    }
  },
  title: {
    text: "",
    align: "center"
  },
  xaxis: {
    categories: [],
    title: {
      text: ""
    },
    labels: {
      formatter: function (val) {
        return val;
      }
    }
  },
  yaxis: {
    title: {
      text: ""
    }
  },
  tooltip: {
    y: {
      formatter: (val) => {
        return val;
      }
    }
  },
  fill: {
    opacity: 1
  },
  legend: {
    position: "top",
    horizontalAlign: "center",
    offsetX: 0
  },

};
